import { bodySmallStyles } from '@lifebit-ai/lds'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.space['05']};
  padding: 0;
`

export const Content = styled.div`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.main};
`

export const Description = styled.div`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.main};
`

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.size.space['05']};
`

export const ErrorMsg = styled.div`
  margin-top: ${({ theme }) => theme.size.space['05']};
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`
