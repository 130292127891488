import { GetSignedUrlForApprovedResultParams } from './types'

export const QueryKeys = {
  GetFileContent: (resultId: string, bucket: string, fileKey: string, fetchSignedUrlOnly: boolean) => [
    'file-content',
    resultId,
    bucket,
    fileKey,
    fetchSignedUrlOnly
  ],
  GetSignedUrlForApprovedResult: (params: Partial<GetSignedUrlForApprovedResultParams>) => [
    'get-signed-url-for-approved-result',
    params
  ],
  GetFileContentsFromSignedUrl: (url?: string) => (url ? ['file-view', url] : ['file-view']),
  GetValidateMagicLink: (token: string) => ['validate-magic-link', token]
} as const
