import { Loader } from '@lifebit-ai/lds'
import { FileTypes } from 'enums'
import React from 'react'

import FileView from 'components/FileView/FileView'
import { LoadingWrapper } from 'core/Common.styles'

import { InnerWrapper, StyledContent, TextFileContent, Wrapper } from './FileContents.styles'
import { useFileContent } from './hooks'
import { NO_CONTENTS_MESSAGE, getFileExtension, validImageFileExtensions } from './utils'

interface FileContentProps {
  resultId: string
  bucket: string
  fileKey: string
  file: S3FileAsContent
}

const FileContent: React.FC<FileContentProps> = ({ resultId, bucket, fileKey, file }) => {
  const fileExtension = getFileExtension(file.name)

  const isValidImageFileExtension =
    fileExtension !== null &&
    !!validImageFileExtensions.find(imageFileExtension => imageFileExtension.toString() === fileExtension)

  const isHTMLFileExtension = fileExtension?.toLocaleLowerCase() === FileTypes.HTML

  // Only directly fetch the file content for HTML files so we can sanitize them.
  const fileContentQuery = useFileContent(resultId, bucket, fileKey, !isHTMLFileExtension)

  if (fileContentQuery.isLoading) {
    return (
      <LoadingWrapper data-testid="file-content-loader">
        <Loader />
      </LoadingWrapper>
    )
  }

  const fileContentData = fileContentQuery.data
  const isFileDataAvailable = !fileContentQuery.isLoading && fileContentData?.signedUrl

  if (!isFileDataAvailable || !fileContentData) {
    return (
      <Wrapper>
        <pre>{NO_CONTENTS_MESSAGE}</pre>
      </Wrapper>
    )
  }

  const isPDFFileExtension = fileExtension?.toLocaleLowerCase() === FileTypes.PDF

  if (isHTMLFileExtension || isPDFFileExtension || isValidImageFileExtension) {
    return (
      <Wrapper>
        <InnerWrapper>
          <StyledContent>
            <iframe
              title={`${fileExtension?.toLocaleLowerCase()}FilePreview`}
              src={fileContentData.signedUrl}
              scrolling="auto"
              height="650"
              width="100%"
              data-testid={`${fileExtension?.toLocaleLowerCase()}-file-preview`}
              frameBorder="0"
            />
          </StyledContent>
        </InnerWrapper>
      </Wrapper>
    )
  }

  // Generic file content
  return (
    <Wrapper>
      <TextFileContent data-testid="lazy-log-viewer">
        <FileView url={fileContentData.signedUrl} />
      </TextFileContent>
    </Wrapper>
  )
}

export default FileContent
