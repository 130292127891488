import { Button, icons, useToast } from '@lifebit-ai/lds'
import { ResultsType } from 'enums'
import React from 'react'
import { useMutation } from 'react-query'

import { getResultsRequestedForExport } from 'services/results'
import { formatDate } from 'utils/date'
import { convertToReadableData, downloadDataAsFile, jsonArrayToCsv } from 'utils/export'

import { getExportRequestName } from './utils'

interface ExportAsCsvButtonProps {
  resultType: ResultsType
  searchTerm?: string
  disabled?: boolean
}

export const PAGE_SIZE_FOR_EXPORT = 100000
export const PAGE_FOR_EXPORT = 0 // 0-indexed
export const EXPORT_ERROR_MSG = 'Error exporting data.'
export const EXPORT_CSV_BUTTON_TEST_ID = 'export-to-csv-button'

export interface ColumnForExport {
  title: string
  render: (item: Result) => string
}

export const columnsForFilesAndFolders: ColumnForExport[] = [
  {
    title: 'Status',
    render: data => data.status
  },
  {
    title: 'Name',
    render: data => getExportRequestName(data)
  },
  {
    title: 'Workspace name',
    render: data => data.workspaceName || ''
  },
  {
    title: 'Workspace ID',
    render: data => data.workspaceId || ''
  },
  {
    title: 'Requested by',
    render: data => data.requestingUser?.email || ''
  },
  {
    title: 'Requested on',
    render: data => formatDate(data.createdAt)
  }
]

export const columnsForAnalysis: ColumnForExport[] = [
  {
    title: 'Status',
    render: data => data.status
  },
  {
    title: 'Workspace name',
    render: data => data.workspaceName || ''
  },
  {
    title: 'Workspace ID',
    render: data => data.resourceTypeName || '-'
  },
  {
    title: 'Analysis name',
    render: data => data.description
  },
  {
    title: 'Pipeline',
    render: data => data.resourceTypeName || '-'
  },
  {
    title: 'Requested by',
    render: data => data.requestingUser?.email || ''
  }
]

const ExportAsCsvButton: React.FC<ExportAsCsvButtonProps> = ({ resultType, searchTerm, disabled }) => {
  const toast = useToast()

  const exportMutation = useMutation({
    mutationFn: () => {
      return getResultsRequestedForExport(PAGE_FOR_EXPORT, PAGE_SIZE_FOR_EXPORT, resultType, searchTerm)
    }
  })

  const getCsvName = (): string => {
    return `results_report_${resultType}.csv`
  }

  const columns = resultType === ResultsType.FilesFolders ? columnsForFilesAndFolders : columnsForAnalysis

  const handleDownload = async () => {
    try {
      const exportData = await exportMutation.mutateAsync()

      const csvData = jsonArrayToCsv(convertToReadableData(exportData.data, columns))

      downloadDataAsFile(csvData, getCsvName(), 'text/csv')
    } catch (err) {
      toast({ message: EXPORT_ERROR_MSG })
    }
  }

  return (
    <Button
      data-testid={EXPORT_CSV_BUTTON_TEST_ID}
      loading={exportMutation.isLoading}
      disabled={disabled || exportMutation.isLoading}
      onClick={() => {
        handleDownload()
      }}
    >
      <icons.DownloadSmall />
      Export to CSV
    </Button>
  )
}

export default ExportAsCsvButton
