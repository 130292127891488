import { Tooltip } from '@lifebit-ai/lds'
import React from 'react'

import { TooltipPropsWithTitle } from 'antd/lib/tooltip'

import { TextContent, TextWrapper } from './PopoverForOverflownText.styles'

const POPOVER_FOR_OVERFLOW_WRAPPER_TESTID = (title: string) => `popover-for-overflow-wrapper-${title}-testid`
export const POPOVER_FOR_OVERFLOW_TEXT_TESTID = 'popover-for-overflow-text-testid'

// text overflow is triggered when the scroll width
// is greater than the offset width
const isCutOff = <T extends HTMLElement>(node: T | null) => (node ? node.offsetWidth < node.scrollWidth : false)

const useIsTextCutOff = <T extends HTMLElement>(ref: React.RefObject<T>): [boolean, () => void] => {
  // since the ref is null at first, we need a stateful value
  // to set after we have a ref to the node
  const [isTextCutOff, setIsTextCutOff] = React.useState(isCutOff(ref.current))

  // allow the caller to refresh on account of refs being
  // outside of the render cycle
  const refresh = () => {
    setIsTextCutOff(isCutOff(ref.current))
  }

  return [isTextCutOff, refresh]
}

interface PopoverForOverflownTextProps
  extends Omit<
    TooltipPropsWithTitle,
    'builtinPlacements' | 'arrowPointAtCenter' | 'arrow' | 'color' | 'overlayStyle' | 'overlayInnerStyle' | 'align'
  > {
  children: React.ReactNode
  wrap?: boolean
}
const PopoverForOverflownText: React.FC<PopoverForOverflownTextProps> = ({ children, wrap, title, ...otherProps }) => {
  const labelRef = React.useRef<HTMLDivElement>(null)
  const [isTextCutOff, refresh] = useIsTextCutOff(labelRef)

  return (
    <Tooltip title={title} zIndex={isTextCutOff ? 1 : -1} {...otherProps}>
      <TextWrapper data-testid={typeof title === 'string' ? POPOVER_FOR_OVERFLOW_WRAPPER_TESTID(title) : ''}>
        <TextContent wrap={wrap} data-testid={POPOVER_FOR_OVERFLOW_TEXT_TESTID} onMouseEnter={refresh} ref={labelRef}>
          {children}
        </TextContent>
      </TextWrapper>
    </Tooltip>
  )
}

export default PopoverForOverflownText
