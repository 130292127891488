import { Input, icons } from '@lifebit-ai/lds'
import React from 'react'

import * as styles from './Search.styles'

type SearchProps = {
  handleChange: (searchTerm: string) => void
  term: string
  placeholder?: string
  testId?: string
  disabled?: boolean
}

const Search: React.FC<SearchProps> = ({ handleChange, term, placeholder, testId, disabled = false }) => {
  return (
    <Input
      data-testid={testId || 'search-input'}
      value={term}
      iconBefore={<icons.SearchSmall />}
      iconAfter={
        term && (
          <styles.IconContainer onClick={() => handleChange('')}>
            <icons.CrossSmall />
          </styles.IconContainer>
        )
      }
      placeholder={placeholder}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
      disabled={disabled}
    />
  )
}

export default Search
