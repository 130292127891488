import { Button, Loader, TabPane, Tabs, icons } from '@lifebit-ai/lds'
import { ResultsState } from 'enums'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import ApprovalModal from 'components/ApprovalModal/ApprovalModal'
import RejectionModal from 'components/RejectionModal/RejectionModal'
import { CommonContainer, CommonPageWrapper, LoadingWrapper, Title } from 'core/Common.styles'
import { useS3BrowserV2 } from 'hooks/useS3BrowserV2'
import { getExportRequestName } from 'modules/export-requests/utils'
import { rejectResult } from 'services/results'
import { formatDate } from 'utils/date'

import Reports from './Reports'
import ResultMeta from './ResultMeta'
import {
  BackLinkWrapper,
  ButtonsWrapper,
  ContentWrapper,
  ContentsTitle,
  GoUpContainer,
  GoUpIconContainer,
  HeaderContent,
  HeaderWrapper,
  RejectedAlert,
  ReportMessage,
  ResultContainer,
  StyledBackLinkIcon,
  StyledReportMessage,
  TitleWrapper
} from './Results.styles'
import ResultsList from './ResultsList'
import Tags from './Tags'
import DownloadExpirySelector from './components/DownloadExpirySelector'
import useApproveResult from './hooks/useApproveResult'

interface GoUpProps {
  onClick(): Promise<void>
  currentDir: string
}

const GoUp: React.FC<GoUpProps> = ({ onClick, currentDir }) => {
  return (
    <GoUpContainer onClick={onClick}>
      <GoUpIconContainer>
        <icons.ArrowGoUpMedium />
      </GoUpIconContainer>
      {currentDir}
    </GoUpContainer>
  )
}

// NOTE: the same component is used when result is viewed from: a) data-export and b) subscription
// However there are different routes/params, see AirlockRoutes.
const Results: React.FC = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<string>('tab1')
  const { resultId } = useParams<{ resultId: string; subscriptionId: string }>()
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState<boolean>(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false)
  const [approveHasError, setApproveHasError] = useState<boolean>(false)
  const [rejectHasError, setRejectHasError] = useState<boolean>(false)

  const { result, isLoading, breadcrumbs, contents, openDir, goUpOneLevel, reports, path, bucket, provider, isFile } =
    useS3BrowserV2({
      resultId: resultId as string
    })

  const queryClient = useQueryClient()
  const isReadyForDownload: boolean = !!(resultId && bucket && path)
  const isAnalysis = !!result?.resourceTypeId

  const onClickReject = () => {
    if (!resultId) return
    setIsRejectModalOpen(true)
  }

  const onClickApprove = () => {
    if (!resultId) return

    setIsApprovalModalOpen(true)
  }

  const onClickBack = () => {
    if (!resultId) return
    if (result?.subscription) {
      navigate(`/subscriptions/${result.subscription?._id}`)
    } else {
      navigate(isAnalysis ? `/export-requests/analysis` : `/export-requests/files`)
    }
  }

  const {
    handleApprove,
    isLoading: isApprovalLoading,
    isSuccess: isSuccessApproveResult,
    isError: isErrorApproveResult,
    downloadExpiryOptions,
    selectedDownloadExpiryOptionKey,
    setSelectedDownloadExpiryOptionKey
  } = useApproveResult({
    workspaceId: result?.workspaceId
  })
  useEffect(() => {
    if (!isSuccessApproveResult) return
    queryClient.invalidateQueries(['result-details', resultId])
    setIsApprovalModalOpen(false)
    setApproveHasError(false)
  }, [queryClient, isSuccessApproveResult, resultId])
  useEffect(() => {
    if (!isErrorApproveResult) return
    setApproveHasError(true)
  }, [isErrorApproveResult])

  const { mutateAsync: handleReject, isLoading: isRejectLoading } = useMutation({
    mutationKey: ['result-details', resultId],
    mutationFn: async (reason: string) => {
      if (!resultId || !reason) return

      await rejectResult(resultId, reason)
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['result-details', resultId])
      setIsRejectModalOpen(false)
      setRejectHasError(false)
    },
    onError: () => {
      setRejectHasError(true)
    }
  })

  if (!resultId) return null

  const getTitle = (): string => {
    if (isAnalysis) return result?.description || '--'
    return getExportRequestName(result)
  }

  return (
    <CommonPageWrapper>
      <HeaderWrapper>
        <HeaderContent>
          <BackLinkWrapper>
            <StyledBackLinkIcon onClick={onClickBack} />
          </BackLinkWrapper>
          <TitleWrapper>
            <Title>{getTitle()}</Title>
            {!!result && <Tags result={result} />}
          </TitleWrapper>
          {result?.status === ResultsState.PendingVerification ? (
            <ButtonsWrapper>
              <Button data-testid="approve-button" type="primary" onClick={onClickApprove}>
                Approve
              </Button>
              <Button data-testid="reject-button" type="secondary" onClick={onClickReject} danger>
                Reject
              </Button>
            </ButtonsWrapper>
          ) : null}
        </HeaderContent>
      </HeaderWrapper>

      {isLoading ? (
        <LoadingWrapper data-testid="results-loader">
          <Loader />
        </LoadingWrapper>
      ) : (
        <>
          <ContentWrapper>
            {result?.status === ResultsState.Rejected ? (
              <RejectedAlert data-testid="rejected-alert">
                {`The request was rejected on ${formatDate(result?.verifiedAt)} by ${
                  result?.verifiedBy?.email
                }, with a note: ${result?.rejectionReason}`}
              </RejectedAlert>
            ) : null}
            {(result?.contentDescription || result?.exportReason) && (
              <ResultMeta
                isFile={isFile}
                contentDescription={result?.contentDescription || ''}
                exportReason={result?.exportReason || ''}
              />
            )}

            {/* Tabs for analysis */}
            {isAnalysis && (
              <Tabs activeKey={activeTab} onChange={id => setActiveTab(id)}>
                <TabPane tabKey="tab1" tabName="Results">
                  <ResultContainer>
                    <CommonContainer>
                      {!!breadcrumbs.length && (
                        <ContentsTitle>
                          {<GoUp onClick={goUpOneLevel} currentDir={breadcrumbs?.[breadcrumbs?.length - 1]} />}
                        </ContentsTitle>
                      )}
                      {contents && contents.length > 0 && (
                        <ResultsList
                          resultId={resultId}
                          contents={contents}
                          provider={provider || ''}
                          bucket={bucket || ''}
                          path={path || ''}
                          isFile={isFile}
                          openDir={openDir}
                          isReadyForDownload={isReadyForDownload}
                          isAnalysis={isAnalysis}
                          owner={result?.requestingUser}
                        />
                      )}
                      {contents && contents.length === 0 && (
                        <StyledReportMessage>
                          <ReportMessage>The results of your analysis will appear here.</ReportMessage>
                        </StyledReportMessage>
                      )}
                    </CommonContainer>
                  </ResultContainer>
                </TabPane>
                <TabPane tabKey="tab2" tabName="Reports">
                  <Reports reports={reports} />
                </TabPane>
              </Tabs>
            )}

            {/* No tabs for files/folders */}
            {!isAnalysis && (
              <ResultContainer>
                {!!breadcrumbs.length && (
                  <ContentsTitle>
                    <GoUp onClick={goUpOneLevel} currentDir={breadcrumbs?.[breadcrumbs?.length - 1]} />
                  </ContentsTitle>
                )}
                {contents && contents.length > 0 && (
                  <ResultsList
                    resultId={resultId}
                    contents={contents}
                    provider={provider || ''}
                    bucket={bucket || ''}
                    path={path || ''}
                    isFile={isFile}
                    openDir={openDir}
                    isReadyForDownload={isReadyForDownload}
                    isAnalysis={isAnalysis}
                    owner={result?.requestingUser}
                  />
                )}
                {contents && contents.length === 0 && (
                  <StyledReportMessage>
                    <ReportMessage>The contents of the requested export will appear here.</ReportMessage>
                  </StyledReportMessage>
                )}
              </ResultContainer>
            )}
          </ContentWrapper>
        </>
      )}

      {isApprovalModalOpen && (
        <ApprovalModal
          onClose={() => {
            setIsApprovalModalOpen(false)
            setApproveHasError(false)
          }}
          isOpen={isApprovalModalOpen}
          isLoading={isApprovalLoading}
          action={() => handleApprove(resultId)}
          isError={approveHasError}
          title={isAnalysis ? 'Approve analysis results export?' : 'Approve data export request?'}
          description={`Do you want to approve the request from ${result?.requestingUser?.email} (${
            result?.workspaceName
          }) to export the ${isAnalysis ? 'results' : 'data'}?`}
          okTitle="Yes, approve"
          content={
            <DownloadExpirySelector
              options={downloadExpiryOptions}
              value={downloadExpiryOptions[selectedDownloadExpiryOptionKey].label}
              selectedDownloadExpiryOptionKey={selectedDownloadExpiryOptionKey}
              onChange={downloadExpiryOptionKey => setSelectedDownloadExpiryOptionKey(downloadExpiryOptionKey)}
            />
          }
        />
      )}

      {isRejectModalOpen && (
        <RejectionModal
          onClose={() => {
            setIsRejectModalOpen(false)
            setRejectHasError(false)
          }}
          isOpen={isRejectModalOpen}
          isLoading={isRejectLoading}
          action={handleReject}
          isError={rejectHasError}
          title={isAnalysis ? 'Reject analysis results export?' : 'Reject data export request?'}
          description={`Do you want to reject the request from ${result?.requestingUser?.email} (${
            result?.workspaceName
          }) to export the ${isAnalysis ? 'results' : 'data'}?`}
          okTitle="Reject access"
        />
      )}
    </CommonPageWrapper>
  )
}

export default Results
