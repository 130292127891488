import { Button, bodySmallStyles, h4Styles, icons, labelDefaultRegularStyles } from '@lifebit-ai/lds'
import { Collapse } from 'antd'
import { ResultsState } from 'enums'
import styled, { DefaultTheme, css } from 'styled-components'

import { H1 } from 'components/H1/H1'
import { CommonContainer } from 'core/Common.styles'

export const HeaderWrapper = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.color.border.subtle};
  display: flex;
  justify-content: flex-start;
  padding: ${({ theme }) => `${theme.size.layout['02']} ${theme.size.layout['05']}`};
`

export const BackLinkWrapper = styled.div`
  display: flex;
  left: -${({ theme }) => theme.size.space['07']};
  position: absolute;
  top: 0;
`

export const StyledBackLinkIcon = styled(icons.ArrowLeftMedium)`
  cursor: pointer;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.layout['02']};
  padding: ${({ theme }) => `0 ${theme.size.layout['05']} ${theme.size.layout['02']}`};
`

export const TitleWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.space['03']};
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.font.heading.fontSize.small};
`

export const TagsWrapper = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.secondary};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.size.space['05']};
`

export const TagWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.size.space['02']};
`

export const TagLabel = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.main};
`

export const TagIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    path {
      fill: ${({ theme }) => theme.color.content.secondary};
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: ${({ theme }) => theme.size.space['03']};
`

export const StyledRejectButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.color.feedback.danger.default};
  padding: ${({ theme }) => `0 ${theme.size.space['06']}`};
`

export const ContentsActionsContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-left: auto;
`

const getStatusIconColor = (status: ResultsState, theme: DefaultTheme) => {
  switch (status) {
    case ResultsState.Complete:
      return theme.color.feedback.success.default
    case ResultsState.PendingVerification:
      return theme.color.feedback.warning.default
    case ResultsState.Rejected:
      return theme.color.feedback.danger.default
    default:
      return theme.color.content.main
  }
}

export const StatusIconWrapper = styled.div<{ status: ResultsState }>`
  display: flex;
  align-items: center;
  margin-right: 4px;

  svg {
    path {
      fill: ${({ status, theme }) => getStatusIconColor(status, theme)};
    }
  }
`

export const StatusWrapper = styled.div`
  ${labelDefaultRegularStyles};
  align-items: center;
  color: ${({ theme }) => theme.color.content.main};
  display: flex;
`

export const ContentsTitle = styled.div`
  font-size: ${({ theme }) => theme.font.heading.fontSize.small};
  font-weight: ${({ theme }) => theme.font.heading.fontWeight.medium};
  color: ${({ theme }) => theme.color.content.secondary};
  padding-bottom: ${({ theme }) => theme.size.space['05']};
  display: flex;
  align-items: center;
`

export const GoUpContainer = styled.div`
  cursor: pointer;
  display: flex;
`

export const GoUpIconContainer = styled.div`
  display: flex;
  margin-right: 8px;
  svg {
    path {
      fill: ${({ theme }) => theme.color.content.secondary};
    }
  }
`

export const StyledHeader = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.size.space['04']};
  background: ${({ theme }) => theme.color.background.highlight};
  color: ${({ theme }) => theme.color.content.main};
  border-radius: ${({ theme }) => theme.size.border.radius['03']};
  font-weight: ${({ theme }) => theme.font.label.fontWeight.default.semibold};
`

export const StyledBody = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.font.body.fontSize.small};
  color: ${({ theme }) => theme.color.content.main};
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.border.divider};
  }
`

export const StyledAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-left: 24px;
  gap: ${({ theme }) => theme.size.space['04']};
  width: 70px;
  path,
  g {
    fill: ${({ theme }) => theme.color.content.main};
  }
`

export const RejectedAlert = styled.div`
  ${labelDefaultRegularStyles};
  background: ${({ theme }) => theme.color.feedback.danger.subtle};
  color: ${({ theme }) => theme.color.feedback.danger.hover};
  padding: 12px;
  border-radius: 8px;
`

export const ReportMessage = styled(H1)`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  font-size: ${({ theme }) => theme.size.font['2-xsmall']};
  line-height: ${({ theme }) => theme.font.lineheight['02']};
  text-align: center;
`

export const StyledReportMessage = styled(CommonContainer)`
  display: flex;
  justify-content: center;
  height: 152px;
  align-items: center;
`

export const ReportWrapper = styled.div`
  padding-top: ${({ theme }) => theme.size.space['07']};
  height: 90vh;
`

export const ResultContainer = styled.div`
  background: ${({ theme }) => theme.color.background.surface};
  box-shadow: ${({ theme }) => theme.shadow['03']};
  border-radius: ${({ theme }) => theme.size.border.radius['04']};
  overflow: clip;
  padding: ${({ theme }) => theme.size.space['05']};
`

export const StyledNameContents = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.size.space['04']};
  }
`

export const StyledResultName = styled.div`
  align-items: center;
  display: flex;
  width: 50%;
`

export const StyledResultSize = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.size.space['04']};
`

export const StyledResultTime = styled.div`
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.size.space['04']};
`

export const StyledResultOwner = styled.div`
  width: 23%;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const iconCss = css`
  &:hover {
    // There is no design for this.
    opacity: 0.5;
    cursor: pointer;
  }
`
export const CopyUrlIcon = styled(icons.LinkSmall)`
  ${iconCss};
`

export const DownloadIcon = styled(icons.DownloadSmall)`
  ${iconCss};
`

export const ViewDetailsIcon = styled(icons.EyeSmall)`
  ${iconCss};
`

export const GotoYourDataIcon = styled(icons.OpenSmall)`
  ${iconCss};
`

export const StyledCollapse = styled(Collapse)`
  margin-top: ${({ theme }) => theme.size.space['06']};
  padding: 0 ${({ theme }) => theme.size.space['05']};
  background: ${({ theme }) => theme.color.background.surface};
  border: none;
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse-item {
    ${h4Styles};
    border-bottom: 1px solid ${({ theme }) => theme.color.border.disabled};
  }
  .ant-collapse-item:last-child {
    border-bottom: 0;
  }
  .ant-collapse-content-box {
    ${bodySmallStyles}
  }
  .ant-collapse-header,
  .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
  svg {
    path {
      fill: ${({ theme }) => theme.color.content.secondary};
    }
  }
`
