import api from 'api'
import axios from 'axios'
import { ResultsState, ResultsType } from 'enums'
import { getAirlockBeUrl } from 'env'

import {
  GetSignedUrlForApprovedResultParams,
  GetSignedUrlForApprovedResultResponse,
  UpdateApproveResultParams
} from './types'

export const DEFAULT_PAGE_SIZE = 10

// Used in old (V1) interface
export const getResults = async (): Promise<ResultsInfo> => {
  const result = await api.get<ResultsInfo>(`/results/verification`, {
    params: {
      status: ResultsState.PendingVerification
    }
  })
  return result.data
}

export const getResultsRequestedForExport = async (
  pageNumber: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  resultType?: ResultsType,
  searchTerm?: string
): Promise<ResultsInfo> => {
  const results = await api.get<ResultsInfo>(`/results/verification`, {
    params: {
      page: pageNumber,
      perPage: pageSize,
      ...(resultType && { resultType }),
      ...(searchTerm && { search: searchTerm })
    }
  })
  return results.data
}

export const getResultsBySubscriptionId = async (
  subscriptionId: string,
  pageNumber: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  searchTerm?: string
): Promise<SubscriptionResultsResponse> => {
  const params = {
    page: pageNumber,
    perPage: pageSize,
    ...(searchTerm ? { search: searchTerm } : {})
  }
  const result = await api.get<SubscriptionResultsResponse>(`/subscriptions/${subscriptionId}/results`, {
    params
  })
  return result.data
}

export const getResultDetails = async (runId: string): Promise<Result> => {
  const result = await api.get<Result>(`/results/verification/${runId}`)
  return result.data
}

export const listBucketContents = async (runId: string, bucket: string, prefix: string): Promise<S3BucketResponse> => {
  const result = await api.get<S3BucketResponse>(`/results/verification/${runId}/listBucketContents`, {
    params: {
      bucket,
      prefix
    }
  })
  return result.data
}

export const getFileContent = async (
  runId: string,
  bucket: string,
  fileKey: string,
  fetchSignedUrlOnly?: boolean
): Promise<GetFileContentResponse> => {
  const response = (
    await api.get<GetFileContentResponse>(`/results/verification/${runId}/fileContent`, {
      params: {
        bucket,
        prefix: fileKey
      }
    })
  ).data

  if (!fetchSignedUrlOnly) {
    response.fileContent = await getFileContentBySignedUrl(response.signedUrl)
  }

  return response
}

export const rejectResult = async (resultId: string, reason: string) => {
  return (await api.put<GetFileContentResponse>(`/results/verification/${resultId}/reject`, { reason })).data
}

export const approveResult = async (resultId: string, params: UpdateApproveResultParams) => {
  const { data } = await api.put<GetFileContentResponse>(`/results/verification/${resultId}/approve`, { ...params })
  return data
}

export const getSignedUrlForApprovedResult = async ({ resultId, token }: GetSignedUrlForApprovedResultParams) => {
  const { data } = await axios.get<GetSignedUrlForApprovedResultResponse>(
    `${getAirlockBeUrl()}/results/${resultId}/access`,
    {
      params: { token }
    }
  )
  return data
}

export const getFileContentBySignedUrl = async (
  signedUrl: string,
  onDownloadProgress?: (event: ProgressEvent) => void,
  signal?: AbortSignal
): Promise<FileContent> => {
  const { data } = await axios.get<FileContent>(signedUrl, { signal, onDownloadProgress })
  return data
}
