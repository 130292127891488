import React from 'react'

import Accordion from 'modules/general/accordion/Accordion'

import { ResultMetaContainer } from './ResultMeta.styles'

interface ResultMetaProps {
  isFile: boolean
  contentDescription: string
  exportReason: string
}

const ResultMeta: React.FC<ResultMetaProps> = ({ isFile, contentDescription, exportReason }) => {
  return (
    <ResultMetaContainer data-testid="result-meta">
      {contentDescription && (
        <Accordion expanded title={isFile ? 'File description' : 'Folder description'}>
          <div>{contentDescription}</div>
        </Accordion>
      )}
      {exportReason && (
        <Accordion title="Reason for export">
          <div>{exportReason}</div>
        </Accordion>
      )}
    </ResultMetaContainer>
  )
}

export default ResultMeta
