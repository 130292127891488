import {
  PageContainer,
  labelDefaultMediumStyles,
  labelDefaultRegularStyles,
  labelLargeMediumStyles
} from '@lifebit-ai/lds'
import styled from 'styled-components'

export const UserSettingsMainContainer = styled.div`
  position: relative;
`

export const UserSettingsAvatar = styled.div`
  ${labelDefaultMediumStyles};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: ${({ theme }) => theme.size.space['025']};
  background: #fbb13c;
  color: ${({ theme }) => theme.color.background.contrast};
`

export const UserSettingsLargeAvatar = styled(UserSettingsAvatar)`
  width: 36px;
  height: 36px;
`

export const UserSettingsPopoverContainer = styled(PageContainer)`
  background: ${({ theme }) => theme.color.background.main};
  position: absolute;
  left: calc(100% + ${({ theme }) => theme.size.space['05']});
  bottom: 0;
  border-radius: ${({ theme }) => theme.size.border.radius['04']};
  gap: ${({ theme }) => theme.size.space['03']};
  width: 242px;
  padding: 0;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.size.space['04']};
  padding: ${({ theme }) => theme.size.space['05']};
  border-bottom: 1px solid ${({ theme }) => theme.color.border.subtle};
  cursor: default;
`

export const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.size.space['01']};
`

export const UserName = styled.div`
  color: ${({ theme }) => theme.color.content.main};
  word-break: break-all;
  ${labelLargeMediumStyles};
`

export const UserEmail = styled.div`
  color: ${({ theme }) => theme.color.content.secondary};
  word-break: break-all;
  ${labelDefaultRegularStyles};
`

export const ManageAccountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.size.space['05']};
  border-bottom: 1px solid ${({ theme }) => theme.color.border.subtle};
  ${labelDefaultRegularStyles}
`

export const ManageAccountText = styled.div`
  ${labelDefaultRegularStyles}
`

export const LogoutContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.size.space['05']};
`

export const LogoutText = styled.div`
  ${labelDefaultRegularStyles}
`
