import { Table } from 'antd'
import styled from 'styled-components'

import { antdScrollableTableStyles } from 'core/AntdTable.styles'
import { labelDefaultRegularStyles } from '@lifebit-ai/lds'

export const StyledTypedTable = styled(Table<Result>)`
  ${antdScrollableTableStyles}

  &:hover {
    cursor: pointer;
  }
`

export const LoadingContainer = styled.div`
  svg {
    height: ${({ theme }) => theme.size.icon.small};
    width: ${({ theme }) => theme.size.icon.small};
  }
`

export const EmptyStateContainer = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.main};
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.size.space['08']};
  width: 100%;
`