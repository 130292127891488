import { bodySmallStyles } from '@lifebit-ai/lds'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.background.contrast};
  margin-bottom: 0;
  overflow: hidden;

  > div {
    border-radius: 0;
  }
`
export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.background.contrast};
  color: ${({ theme }) => theme.color.content.contrast};
  font-size: 10px;
  height: 100%;
  max-height: 70vh;
  padding: 0;

  div {
    white-space: nowrap;

    &::-webkit-scrollbar-track {
      background: #ffffff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #6f777c;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
  }
`

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  max-height: 70vh;
  color: ${({ theme }) => theme.color.content.contrast};
  ${bodySmallStyles};
`

export const Number = styled.span`
  opacity: 0.7;
  margin-right: ${({ theme }) => theme.size.space['05']};
`
