import axios from 'axios'
import { HttpStatusCodes } from 'enums'
import { getAirlockBeUrl } from 'env'

import { getCurrentUser, signOut } from './auth/airlockBe'

const api = axios.create({
  withCredentials: true,
  baseURL: getAirlockBeUrl()
})

api.interceptors.request.use(async config => {
  const tokenId: string | undefined = getCurrentUser()?.token // Add logic to retrieve User token
  config.headers = {
    ...config.headers,
    Authorization: config.headers?.Authorization ?? 'Bearer ' + tokenId
  }
  return config
})

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      if (
        error.response.status === HttpStatusCodes.Unauthorized ||
        (error.response.status === HttpStatusCodes.InternalServerError &&
          error.response.data.message?.toLowerCase() === 'jwt expired')
      ) {
        signOut()
      }
    }

    return Promise.reject(error)
  }
)

export default api
