import styled from 'styled-components'

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`

interface TextContextProps {
  wrap?: boolean
}
export const TextContent = styled.span<TextContextProps>`
  ${({ wrap }) =>
    wrap
      ? `
    white-space: wrap;
  `
      : `
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  & > span {
    vertical-align: middle;

    &:first-child {
      margin-right: ${({ theme }) => theme.size.space['01']};
      font-size: 20px;
      width: 20px;
    }
  }

  & > div {
    display: inline-block;
    vertical-align: middle;

    margin-right: ${({ theme }) => theme.size.space['02']};

    & > div {
      align-items: center;
      display: flex;
    }
  }

  & svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
`
