import { Modal, Tooltip, icons, useToast } from '@lifebit-ai/lds'
import { DownloadFileMethods } from 'enums'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import DownloadResultFile from 'modules/resultsV2/DownloadResultFile'
import FileContent from 'modules/resultsV2/FileContent'
import {
  CopyUrlIcon,
  StyledAction,
  StyledBody,
  StyledHeader,
  StyledNameContents,
  StyledResultName,
  StyledResultOwner,
  StyledResultSize,
  StyledResultTime,
  ViewDetailsIcon
} from 'modules/resultsV2/Results.styles'
import { QueryKeys } from 'services/queryKeys'
import { formatDate } from 'utils/date'
import { copyTextToClipboard } from 'utils/string'

const byteConverter = (byte: number): string => {
  return `${byte / 1000}kb`
}

interface DirRowProps {
  dir: S3DirAsContent
  onDoubleClick(): void
}

interface FileRowProps {
  file: S3FileAsContent
}

const DirRow: React.FC<DirRowProps> = ({ dir, onDoubleClick }) => {
  return (
    <StyledNameContents onDoubleClick={onDoubleClick}>
      <icons.FolderMedium data-testid="dir-icon" />
      {dir.name}
    </StyledNameContents>
  )
}

const FileRow: React.FC<FileRowProps> = ({ file }) => {
  return (
    <StyledNameContents>
      <icons.FileMedium data-testid="file-icon" />
      {file.name}
    </StyledNameContents>
  )
}

export const COPY_S3_LINK_ICON_TEST_ID = 'copy-s3-link-icon'

interface ResultsListProps {
  resultId: string
  contents: (S3FileAsContent | S3DirAsContent)[]
  bucket: string
  path: string
  provider: string
  openDir(dirName: string): Promise<void>
  isFile: boolean
  isReadyForDownload: boolean
  isAnalysis: boolean
  owner?: UserInfo
}

const ResultsList: React.FC<ResultsListProps> = ({
  resultId,
  contents,
  bucket,
  path,
  openDir,
  isFile,
  isReadyForDownload,
  isAnalysis,
  owner
}) => {
  const [selectedFileToBePreviewed, setSelectedFileToBePreviewed] = useState<S3FileAsContent | undefined>()
  const queryClient = useQueryClient()
  const onViewFile = (item: S3FileAsContent) => setSelectedFileToBePreviewed(item)
  const toast = useToast()

  const onCopyS3Link = (item: S3FileAsContent): void => {
    const fileKey = isFile ? path : `${path}/${item?.name}`
    copyTextToClipboard(`${bucket}/${fileKey}`)
    toast({ message: 'File link copied to clipboard' })
  }

  return (
    <div>
      {!isFile && (
        <StyledHeader data-testid="contents-table-header">
          <StyledResultName>Name</StyledResultName>
          <StyledResultSize>Size</StyledResultSize>
          <StyledResultTime>Last Modified</StyledResultTime>
        </StyledHeader>
      )}

      {contents.map(item => (
        <StyledBody key={item.name}>
          <StyledResultName>
            {item.isDir ? (
              <DirRow dir={item as S3DirAsContent} onDoubleClick={() => openDir(item.name)} />
            ) : (
              <FileRow file={item as S3FileAsContent} />
            )}
          </StyledResultName>
          <StyledResultSize>{item.isDir ? '..' : byteConverter(item.size)}</StyledResultSize>
          <StyledResultTime>
            {item.isDir ? 'Unknown' : `Last modified on ${formatDate(item.lastModified)}`}
          </StyledResultTime>
          {isAnalysis && isFile && <StyledResultOwner>Owned by {owner?.email || 'Unknown'}</StyledResultOwner>}
          <StyledAction>
            {!item.isDir && (
              <Tooltip title="Preview">
                <ViewDetailsIcon onClick={() => onViewFile(item)} />
              </Tooltip>
            )}
            {!item.isDir && (
              <Tooltip title="Copy S3 link">
                <CopyUrlIcon onClick={() => onCopyS3Link(item)} data-testid={COPY_S3_LINK_ICON_TEST_ID} />
              </Tooltip>
            )}
            {!item.isDir && isReadyForDownload ? (
              <Tooltip title="Download file">
                <>
                  <DownloadResultFile
                    bucket={bucket as string}
                    resultId={resultId as string}
                    fileKey={`${isFile ? path : `${path}/${item.name}`}`}
                    fileName={item.name}
                    method={DownloadFileMethods.DownloadInTabWithHiddenLink}
                  />
                </>
              </Tooltip>
            ) : null}
          </StyledAction>
        </StyledBody>
      ))}

      <Modal
        onCancel={() => {
          queryClient.cancelQueries(QueryKeys.GetFileContentsFromSignedUrl())
          setSelectedFileToBePreviewed(undefined)
        }}
        open={!!selectedFileToBePreviewed}
        title={`View details: ${selectedFileToBePreviewed?.name}`}
        size="large"
        closable
      >
        {bucket && path && selectedFileToBePreviewed && (
          <FileContent
            file={selectedFileToBePreviewed}
            resultId={resultId as string}
            bucket={bucket}
            fileKey={isFile ? path : `${path}/${selectedFileToBePreviewed?.name}`}
          />
        )}
      </Modal>
    </div>
  )
}

export default ResultsList
