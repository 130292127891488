import { Label } from '@lifebit-ai/lds'
import { useFormikContext } from 'formik'
import React from 'react'

import { FormRow } from 'components/Forms/Form.styles'

import { BulkAuthFormData } from './BulkAuthModal'
import { ReviewMessage, StepContentWrapper } from './BulkAuthModal.styles'
import { ReasonWrapper, StyledCheckbox, StyledTextArea } from './BulkAuthModal.styles'

interface Props {
  hasReason: boolean
  message: string
  supportsBulkOperation: boolean
  applyToAllLabel: string
}

const ReviewStep: React.FC<Props> = ({ message, hasReason, supportsBulkOperation, applyToAllLabel }) => {
  const { values, setFieldValue, handleChange, errors, touched } = useFormikContext<BulkAuthFormData>()
  return (
    <StepContentWrapper>
      <ReviewMessage>{message}</ReviewMessage>
      {hasReason && (
        <ReasonWrapper>
          <StyledTextArea
            textArea
            id="reason"
            data-testid="reject-reason"
            label={<Label>Please provide the reason</Label>}
            // @ts-expect-error Property 'value' does not exist on type 'IntrinsicAttributes
            value={values.reason}
            placeholder="Reason"
            onChange={handleChange}
            status={{
              //@ts-ignore
              type: touched?.reason && !!errors.reason ? 'error' : undefined,
              text: touched?.reason && !!errors.reason ? errors.reason : undefined
            }}
          />
        </ReasonWrapper>
      )}
      {supportsBulkOperation && (
        <FormRow>
          <StyledCheckbox
            data-testid="bulk-checkbox"
            checked={values.isBulkOperation}
            label={applyToAllLabel}
            onClick={ev => {
              setFieldValue('isBulkOperation', !values.isBulkOperation)
            }}
          />
        </FormRow>
      )}
    </StepContentWrapper>
  )
}

export default ReviewStep
