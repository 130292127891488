import { icons, useToast } from '@lifebit-ai/lds'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import useUser from 'hooks/useUser'

import * as styles from './UserSettingsPopover.styles'

export const USER_SETTINGS_AVATAR_TEST_ID = 'user-settings-avatar-test-id'
export const USER_SETTINGS_POPOVER_TEST_ID = 'user-settings-popover-test-id'

export const ACCOUNT_MENU_ITEM_TEXT = 'Manage Account'
export const LOGOUT_MENU_ITEM_TEXT = 'Log out'

enum UserSettingsOptions {
  Logout = 'logout'
}

const UserSettingsPopover: React.FC = () => {
  const [isPopoverVisible, togglePopoverVisibility] = useState<boolean>(false)
  const toast = useToast()
  const { user, logout } = useUser()

  const navigate = useNavigate()

  const userFirstChar: string = user?.user.email?.charAt(0) || '?'

  const handleOnMenuItemClick = (menuOption: UserSettingsOptions) => {
    switch (menuOption) {
      case UserSettingsOptions.Logout:
        logout()
        toast({ message: 'Logout successful.' })
        navigate('/')
        break
    }
  }

  return (
    <styles.UserSettingsMainContainer tabIndex={0} onBlur={() => togglePopoverVisibility(false)}>
      <styles.UserSettingsAvatar
        data-testid={USER_SETTINGS_AVATAR_TEST_ID}
        onClick={() => togglePopoverVisibility(!isPopoverVisible)}
      >
        {userFirstChar}
      </styles.UserSettingsAvatar>

      {isPopoverVisible && (
        <styles.UserSettingsPopoverContainer data-testid={USER_SETTINGS_POPOVER_TEST_ID}>
          <styles.HeaderContainer>
            <styles.UserSettingsLargeAvatar>{userFirstChar}</styles.UserSettingsLargeAvatar>
            <styles.HeaderRightContainer>
              <styles.UserEmail>{user?.user.email}</styles.UserEmail>
            </styles.HeaderRightContainer>
          </styles.HeaderContainer>
          <styles.LogoutContainer onClick={() => handleOnMenuItemClick(UserSettingsOptions.Logout)}>
            <styles.LogoutText>{LOGOUT_MENU_ITEM_TEXT}</styles.LogoutText>
            <icons.LogoutSmall />
          </styles.LogoutContainer>
        </styles.UserSettingsPopoverContainer>
      )}
    </styles.UserSettingsMainContainer>
  )
}

export default UserSettingsPopover
