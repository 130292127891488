import styled from 'styled-components'

export const ResultMetaContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadow['03']};
  border-radius: ${({ theme }) => theme.size.border.radius['04']};
  overflow: clip;

  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.color.border.subtle};
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: ${({ theme }) => theme.size.border.radius['04']};
    border-bottom-right-radius: ${({ theme }) => theme.size.border.radius['04']};
  }
`
