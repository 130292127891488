import styled from 'styled-components'

export const IconContainer = styled.div`
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.color.content.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.color.content.main};
      }
    }
  }
`
