import { TabPane, Tabs } from '@lifebit-ai/lds'
import { ResultsType } from 'enums'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BodyWrapper, CommonPageWrapper, Title, TitleWrapper } from 'core/Common.styles'
import NotFound from 'modules/general/not-found/NotFound'

import ExportRequestsTable from './ExportRequestsTable'

export enum ExportRequestsTabKeys {
  Files = 'files',
  Analysis = 'analysis'
}

export const ExportRequestsPage: React.FC = () => {
  const { tabId } = useParams<{ tabId?: ExportRequestsTabKeys }>()
  const navigate = useNavigate()

  if (tabId && ![ExportRequestsTabKeys.Files, ExportRequestsTabKeys.Analysis].includes(tabId)) return <NotFound />

  return (
    <CommonPageWrapper>
      <TitleWrapper>
        <Title>Data export requests</Title>
      </TitleWrapper>

      <BodyWrapper>
        <Tabs
          activeKey={tabId || ExportRequestsTabKeys.Files}
          onChange={(tab: string) => navigate(`/export-requests/${tab}`)}
        >
          <TabPane tabName="Files / Folders" tabKey={ExportRequestsTabKeys.Files}>
            <ExportRequestsTable resultType={ResultsType.FilesFolders} />
          </TabPane>
          <TabPane tabName="Analysis" tabKey={ExportRequestsTabKeys.Analysis}>
            <ExportRequestsTable resultType={ResultsType.Analysis} />
          </TabPane>
        </Tabs>
      </BodyWrapper>
    </CommonPageWrapper>
  )
}

export default ExportRequestsPage
