import styled from 'styled-components'

export const TitleWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.border.subtle};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.size.layout['02']} ${theme.size.layout['03']}`};
`

export const BodyWrapper = styled.div`
  padding: ${({ theme }) => `0 ${theme.size.layout['03']} ${theme.size.layout['03']}`};
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.color.content.main};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.heading.fontSize.medium};
  font-family: ${({ theme }) => theme.font.family};
  margin: 0 !important;
`

export const H5 = styled.h5`
  color: ${({ theme }) => theme.color.content.main};
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.heading.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`

export const CommonContainer = styled.div`
  background: ${({ theme }) => theme.color.background.surface};
  border-radius: ${({ theme }) => theme.size.border.radius['04']};
  padding: ${({ theme }) => theme.size.space['06']};
`

export const CommonPageWrapper = styled.div<{ centered?: boolean }>`
  background: ${({ theme }) => theme.color.background.main};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.layout['02']};
  margin: 0 ${({ centered }) => (centered ? 'auto' : 0)};
  max-width: ${({ centered }) => (centered ? '935px' : 'none')};
  min-height: 100vh;
  width: 100%;
`

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const TableContainer = styled.div`
  background: ${({ theme }) => theme.color.background.surface};
  border-radius: ${({ theme }) => theme.size.border.radius['03']};
  box-shadow: ${({ theme }) => theme.shadow['03']};
  overflow: hidden;
  width: 100%;
`

export const TablePaginationContainer = styled.div`
  margin: ${({ theme }) => theme.size.space['04']};
`

export const Section = styled.section`
  background: ${({ theme }) => theme.color.content.contrast};
  border-radius: ${({ theme }) => theme.size.space['03']};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.size.space['08']};
`

export const LoadingWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.size.layout['02']} 0;
  > div {
    margin: auto;
  }
`
