import { icons } from '@lifebit-ai/lds'
import { SubscriptionMemberStatuses } from 'enums'
import React from 'react'

import { StatusIconWrapper } from './MemberStatusIcon.styles'

interface MemberStatusIconProps {
  status: SubscriptionMemberStatuses
}

const getStatusIcon = (status: SubscriptionMemberStatuses): React.ReactElement | null => {
  switch (status) {
    case SubscriptionMemberStatuses.Active:
      return <icons.CheckInCircleSmall data-testid="member-active-icon" />
    case SubscriptionMemberStatuses.PendingApproval:
      return <icons.ClockSmall data-testid="member-pending-approval-icon" />
    case SubscriptionMemberStatuses.PendingInvitation:
    case SubscriptionMemberStatuses.PendingActivation:
      return <icons.PreparingCircleSmall data-testid="member-pending-icon" />
    case SubscriptionMemberStatuses.PendingRemoval:
      return <icons.WarningSmall data-testid="member-pending-removal-icon" />
    case SubscriptionMemberStatuses.Disabled:
      return <icons.CloseInCircleSmall data-testid="member-removed-icon" />
    default:
      return null
  }
}

const MemberStatusIcon: React.FC<MemberStatusIconProps> = ({ status }) => {
  const icon = getStatusIcon(status)
  if (!icon) return null

  return <StatusIconWrapper status={status}>{icon}</StatusIconWrapper>
}

export default MemberStatusIcon
