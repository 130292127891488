import { Loader, Table, TableColumn } from '@lifebit-ai/lds'
import { AxiosError } from 'axios'
import { ResultsType } from 'enums'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import Search from 'components/Search/Search'
import { TableContainer, TablePaginationContainer, TableWrapper } from 'core/Common.styles'
import useDebounce from 'hooks/useDebounce'
import { ActionsContainer, SearchContainer } from 'modules/subscriptions/analysis-results/AnalysisResults.styles'
import { DEFAULT_PAGE_SIZE, getResultsRequestedForExport } from 'services/results'

import ExportAsCsvButton from './ExportAsCsvButton'
import { EmptyStateContainer, LoadingContainer } from './ExportRequestsTable.styles'
import { columnsForAnalysis, columnsForFilesAndFolders } from './exportRequestsTableColumns'

export const EXPORT_REQUEST_TABLE_WRAPPER_TEST_ID = 'export-requests-table-wrapper-test-id'

interface ExportRequestsTableProps {
  resultType: ResultsType
}

const ExportRequestsTable: React.FC<ExportRequestsTableProps> = ({ resultType }) => {
  const [tableState, setTableState] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 200)

  const { data: paginatedResponse, isLoading: isLoadingResults } = useQuery<
    {},
    AxiosError,
    ResultsRequestedForExportResponse
  >({
    queryKey: [
      `${resultType}-export-requests`,
      tableState.page + 1,
      tableState.pageSize,
      resultType,
      debouncedSearchTerm
    ],
    queryFn: () =>
      getResultsRequestedForExport(tableState.page + 1, tableState.pageSize, resultType, debouncedSearchTerm),
    refetchInterval: 5000,
    retry: false
  })

  const navigate = useNavigate()

  const openResults = (resultId: string): void => {
    if (!resultId) return
    navigate(`/export-requests/view/${resultId}`)
  }

  const columns: TableColumn<Result>[] =
    resultType === ResultsType.FilesFolders ? columnsForFilesAndFolders : columnsForAnalysis

  return (
    <TableWrapper data-testid={EXPORT_REQUEST_TABLE_WRAPPER_TEST_ID}>
      <SearchContainer>
        <Search
          testId="search-input"
          handleChange={newTerm => {
            setSearchTerm(newTerm)
            setTableState(prev => ({ ...prev, page: 0 }))
          }}
          term={searchTerm}
          placeholder="Search"
        />
        <ActionsContainer>
          <ExportAsCsvButton
            disabled={!paginatedResponse?.data.length}
            resultType={resultType}
            searchTerm={debouncedSearchTerm}
          />
        </ActionsContainer>
      </SearchContainer>

      <Table<Result>
        rowKey="_id"
        data-testid="export-requests-table"
        columns={columns}
        data={paginatedResponse?.data ?? []}
        onRowClicked={(_, row) => openResults(row.original._id)}
        loading={isLoadingResults}
        loadingIcon={
          <LoadingContainer>
            <Loader text={false} />
          </LoadingContainer>
        }
        emptyComponent={<EmptyStateContainer>Your results will appear in here</EmptyStateContainer>}
        pagination={{
          pageSize: tableState.pageSize,
          currentPage: tableState.page,
          totalRows: paginatedResponse?.pagination?.totalCount || 0,
          pageSizeOptions: [8, 10, 20, 50],
          onPageChange: page => {
            setTableState(prev => ({ ...prev, page }))
          },
          onPageSizeChange: pageSize => {
            setTableState(prev => ({ ...prev, page: 0, pageSize }))
          }
        }}
      >
        <TableContainer>
          <Table.Body />

          {paginatedResponse?.data.length ? (
            <TablePaginationContainer>
              <Table.Pagination />
            </TablePaginationContainer>
          ) : null}
        </TableContainer>
      </Table>
    </TableWrapper>
  )
}

export default ExportRequestsTable
