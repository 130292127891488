import { useQuery } from 'react-query'

import { getFileContent, listBucketContents } from 'services/results'

const MultiQcFileName: string = 'multiqc_report.html'

const useMultiQcFetcher = (runId: string, bucket?: string, rootUrl?: string): string | undefined => {
  const multiQcPath: string = `${rootUrl}/results/MultiQC`

  const multiQcDirQuery = useQuery<S3BucketResponse | undefined>(['s3-contents', bucket, multiQcPath], async () => {
    if (typeof bucket === 'undefined' || typeof rootUrl === 'undefined') return undefined
    return listBucketContents(runId, bucket, multiQcPath)
  })

  const doesMultiQcExist: boolean = !!multiQcDirQuery.data?.contents.find(item => item.name === MultiQcFileName)

  const multiQcFileQuery = useQuery<GetFileContentResponse | undefined>({
    queryKey: ['multiqc-file-content', bucket, multiQcPath],
    queryFn: async () => {
      if (typeof bucket === 'undefined' || typeof rootUrl === 'undefined') return undefined
      return getFileContent(runId, bucket, `${multiQcPath}/${MultiQcFileName}`)
    },
    retry: false
  })

  if (!doesMultiQcExist || !multiQcFileQuery.data?.fileContent) return undefined
  return multiQcFileQuery.data?.fileContent
}

export default useMultiQcFetcher
