import { icons } from '@lifebit-ai/lds'
import Envs, { castToStringOrUseDefault } from 'env'
import React from 'react'
import { useNavigate } from 'react-router'

import { ModuleIcon as ModuleIconSvg } from 'img'
import { ExportRequestsTabKeys } from 'modules/export-requests/ExportRequestsPage'

import { ActionBtns, FooterNavItem, HelpButton, LogoContainer, NavHeader, NavbarWrapper } from './Navbar.styles'
import UserSettingsPopover from './UserSettingsPopover'

const HelpLink: React.FC = () => {
  const helpDeskUrl = castToStringOrUseDefault(
    Envs.AIRLOCK_HELPDESK_URL,
    'https://lifebit.atlassian.net/wiki/spaces/CD/pages/1132068882/As+an+Airlock+Manager'
  )

  if (!helpDeskUrl) return null

  return (
    <HelpButton
      data-testid="navbar-help-link"
      onClick={() => {
        window.open(helpDeskUrl, '_blank')
      }}
    >
      <ModuleIconSvg />
    </HelpButton>
  )
}

const Navbar: React.FC = () => {
  const navigate = useNavigate()

  return (
    <NavbarWrapper>
      <NavHeader onClick={() => navigate(`/export-requests/${ExportRequestsTabKeys.Files}`)}>
        <LogoContainer>
          <icons.LifebitMedium />
        </LogoContainer>

        <span>Airlock</span>
      </NavHeader>

      <ActionBtns data-testid="popover-btn">
        <FooterNavItem>
          <HelpLink />
        </FooterNavItem>

        <FooterNavItem>
          <UserSettingsPopover />
        </FooterNavItem>
      </ActionBtns>
    </NavbarWrapper>
  )
}

export default Navbar
