import { AxiosError } from 'axios'
import { HttpStatusCodes } from 'enums'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { setStorageValue } from 'core/local-storage'
import { getUserInfo } from 'services/user'

import { AuthContext } from './AuthContext'
import { USER_SESSION_LOCAL_STORAGE_KEY, getCurrentUser, signIn, signOut } from './airlockBe'

interface AuthContextProviderProps {
  children: React.ReactNode
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
  const [userSession, setUserSession] = useState<UserAuth | undefined>()

  useEffect(() => {
    setUserSession(getCurrentUser())
  }, [])

  const tokenId: string | undefined = getCurrentUser()?.token

  const {
    data: userInfo,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['me', [tokenId]],
    queryFn: () => getUserInfo(),
    retry: false,
    enabled: !!tokenId,
    onError: (error: AxiosError) => {
      // If we get unauthorised error - 401, it means the token has expired or is invalid, so we can safely logout.
      if (error?.response?.status === HttpStatusCodes.Unauthorized) {
        logout()
      }
    }
  })

  const logout = () => {
    if (!userInfo) {
      window.location.reload()
      return
    }
    setUserSession(undefined)
    signOut()
  }

  const login = async (username: string, password: string, otp?: string): Promise<UserAuth | { otp: boolean }> => {
    if (userInfo) throw Error('User is already logged in.')
    const data = await signIn(username, password, otp)

    if (data.otp) {
      return data
    }
    setStorageValue(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(data))
    setUserSession(getCurrentUser())

    return data
  }

  const autoLogin = async (user: UserAuth) => {
    setStorageValue(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(user))
    setUserSession(user)
  }

  return (
    <AuthContext.Provider value={{ autoLogin, login, logout, user: userSession, isLoading, isFetching }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
