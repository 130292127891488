import { AxiosError } from 'axios'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { getFileContent, getResultDetails, listBucketContents } from 'services/results'

import useMultiQcFetcher from './useMultiQcFetcher'

// This is set on api-server
const DEFAULT_DIR_FOR_JOB_RESULTS = 'results'

export interface S3BrowserConfigV2 {
  resultId: string
}

export interface S3BrowserReturnV2 {
  breadcrumbs: string[]
  // If it is undefined, it is still being loaded.
  bucket: string | undefined
  // If it is undefined, it is still being loaded.
  path: string | undefined
  provider: string | undefined
  isLoading: boolean
  contents: (S3FileAsContent | S3DirAsContent)[]
  reports: {
    multiQc: string | undefined
    htmlFileContent: string | undefined
  }
  openDir(dirName: string): Promise<void>
  goUpOneLevel(): Promise<void>
  goToBreadcrumb(atIdx: number): Promise<void>
  isFile: boolean
  result?: Result
}

const getPathFromPrefix = (breadcrumbs: string[], isFile: boolean, rootPrefix?: string): string | undefined => {
  if (typeof rootPrefix === 'undefined') return undefined

  // For a file we return the path as it is in beforeCheckLocation
  if (isFile) {
    return rootPrefix
  }

  const actualRelativeFolderPath = breadcrumbs.join('/')
  // If this has 0 length then it means we are at the base path
  return actualRelativeFolderPath.length
    ? [rootPrefix, DEFAULT_DIR_FOR_JOB_RESULTS, actualRelativeFolderPath].join('/')
    : [rootPrefix, DEFAULT_DIR_FOR_JOB_RESULTS].join('/')
}

export const useS3BrowserV2 = ({ resultId }: S3BrowserConfigV2): S3BrowserReturnV2 => {
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([])

  const runQuery = useQuery<{} | undefined, AxiosError, Result>({
    queryKey: ['result-details', resultId],
    queryFn: async () => {
      if (!resultId) return undefined
      return getResultDetails(resultId)
    },
    onSuccess: data => {
      setBreadcrumbs([])
    },
    retry: false
  })

  const bucket: string | undefined = runQuery.data?.beforeCheckLocation.bucket
  const provider: string | undefined = runQuery.data?.beforeCheckLocation.provider
  const rootPrefix: string | undefined = runQuery.data?.beforeCheckLocation.prefix
  const isFile: boolean = !!runQuery.data?.beforeCheckLocation.isFile
  const isHTMLFile: boolean = !!(isFile && rootPrefix && rootPrefix.indexOf('.html') === rootPrefix.length - 5)

  const path: string | undefined = getPathFromPrefix(breadcrumbs, isFile, rootPrefix)

  const s3BrowseQuery = useQuery<S3BucketResponse | undefined>({
    queryKey: ['s3-contents', bucket, path],
    queryFn: async () => {
      if (!bucket || typeof path === 'undefined') return undefined
      return listBucketContents(resultId, bucket, path)
    },
    retry: false
  })

  const multiQcFileContent: string | undefined = useMultiQcFetcher(resultId, bucket, rootPrefix)

  const { data: htmlFileContent, isLoading: isLoadingHtmlFileContent } = useQuery<GetFileContentResponse | undefined>({
    queryKey: ['html-file-content', bucket, resultId],
    queryFn: async () => {
      if (typeof bucket === 'undefined' || typeof rootPrefix === 'undefined') return undefined
      return getFileContent(resultId, bucket, rootPrefix)
    },
    enabled: isHTMLFile,
    retry: false
  })

  const openDir = async (dirName: string) => {
    setBreadcrumbs(prev => [...prev, dirName])
  }

  const goUpOneLevel = async () => {
    setBreadcrumbs(prev => {
      const newBreadcrumbs = [...prev]
      newBreadcrumbs.pop()
      return newBreadcrumbs
    })
  }

  const goToBreadcrumb = async (atIdx: number) => {
    setBreadcrumbs(prev => {
      if (atIdx >= prev.length || atIdx < 0) throw Error('Cannot go to unexisting breadcrumb.')
      return [...prev].slice(0, atIdx + 1)
    })
  }

  const result: Result | undefined = runQuery.data

  return {
    result,
    breadcrumbs,
    bucket,
    provider,
    path,
    isLoading: s3BrowseQuery.isLoading || runQuery.isLoading || isLoadingHtmlFileContent,
    contents: s3BrowseQuery.data?.contents || [],
    openDir,
    goUpOneLevel,
    goToBreadcrumb,
    isFile,
    reports: {
      htmlFileContent: htmlFileContent?.fileContent,
      multiQc: multiQcFileContent
    }
  }
}

export default useS3BrowserV2
