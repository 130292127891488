import { Button, bodySmallStyles, h1Styles, labelDefaultRegularStyles } from '@lifebit-ai/lds'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const linkStyles = css`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.feedback.action.default};
  text-decoration: underline;
`

export const Title = styled.div`
  ${h1Styles};
  color: ${({ theme }) => theme.color.content.main};
  text-align: center;
`

export const Description = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.main};
  text-align: center;
  margin-top: ${({ theme }) => theme.size.space['03']};
`

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.size.space['07']};
  padding: 0 ${({ theme }) => theme.size.layout['06']} ${({ theme }) => theme.size.layout['03']};
`

export const FormRow = styled.div`
  margin-bottom: ${({ theme }) => theme.size.space['06']};
`

export const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.space['03']};
`

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.color.feedback.danger.default};
`

export const FormButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const InternalLink = styled(Link)`
  ${linkStyles};
`

export const ConfirmationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.size.space['06']};
  padding-bottom: ${({ theme }) => theme.size.layout['04']};
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.space['03']};
  padding: 0 ${({ theme }) => theme.size.space['07']};
`

export const HeaderTitle = styled.div`
  ${h1Styles};
  color: ${({ theme }) => theme.color.content.main};
  font-size: ${({ theme }) => theme.size.font.xlarge};
  text-align: center;
`

export const HeaderDescription = styled.div`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.main};
  text-align: center;
  font-size: ${({ theme }) => theme.size.font['2-xsmall']};
  font-weight: ${({ theme }) => theme.font.weight.regular};

  span {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }
`

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.size.space['06']};
  justify-content: space-between;
`

export const BodyItem = styled.div`
  align-items: center;
  border-radius: ${({ theme }) => theme.size.border.radius['02']};
  background: ${({ theme }) => theme.color.background.highlight};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.size.space['03']};
  padding: ${({ theme }) => theme.size.space['03']} ${({ theme }) => theme.size.space['05']};

  &:hover {
    background: #e3e5e8;
  }
`

export const MailTitle = styled.div`
  color: ${({ theme }) => theme.color.content.main};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
`

export const Footer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.content.main};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.size.font['2-xsmall']};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  gap: ${({ theme }) => theme.size.space['05']};
  justify-content: center;
  padding-top: ${({ theme }) => theme.size.space['06']};

  div {
    ${labelDefaultRegularStyles};

    span {
      color: ${({ theme }) => theme.color.feedback.action.default};
      cursor: pointer;
      text-decoration-line: underline;

      &:hover {
        color: ${({ theme }) => theme.color.feedback.action.hover};
      }
    }
  }
`

export const HelpdeskWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.space['04']};
  margin-top: ${({ theme }) => theme.size.layout['03']};
  text-align: center;
  ${labelDefaultRegularStyles};

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;

    span {
      margin-right: ${({ theme }) => theme.size.space['03']};
    }
  }
`

export const ManualLoginDescriptionContainer = styled.div`
  ${labelDefaultRegularStyles};
  background: ${({ theme }) => theme.color.background.highlight};
  border-radius: ${({ theme }) => theme.size.space['02']};
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.size.space['03']};
  padding: ${({ theme }) => theme.size.space['03']};
`
