import { Loader, icons } from '@lifebit-ai/lds'
import React from 'react'
import { useParams } from 'react-router'

import useQueryParams from 'hooks/useQueryParams'
import { AirlockModuleIcon } from 'img'
import AuthWrapper from 'modules/login/AuthWrapper'

import * as styles from './DownloadPage.styles'
import { useSignedUrlForApprovedResult } from './hooks'

export const DOWNLOAD_PAGE_ERROR_VIEW_TEST_ID = 'download-page-error-view-test-id'
export const DOWNLOAD_PAGE_LOADING_VIEW_TEST_ID = 'download-page-loading-view-test-id'
export const DOWNLOAD_PAGE_SUCCESS_VIEW_TEST_ID = 'download-page-success-view-test-id'

const DownloadPage: React.FC = () => {
  const { resultId } = useParams<{ resultId: string }>()
  const queryParams = useQueryParams()
  const token = queryParams.get('token')

  const { isError, isLoading } = useSignedUrlForApprovedResult({ resultId, token })

  if (isError) {
    return (
      <AuthWrapper>
        <styles.LogoContainer data-testid={DOWNLOAD_PAGE_ERROR_VIEW_TEST_ID}>
          <AirlockModuleIcon />
          <styles.LogoOverlay>
            <icons.ClockMedium />
          </styles.LogoOverlay>
        </styles.LogoContainer>
        <styles.Title>Download link expired</styles.Title>
        <styles.Description>
          Download links expire after 5 minutes. Please re-submit your download request.
        </styles.Description>
      </AuthWrapper>
    )
  }

  if (isLoading) {
    return (
      <AuthWrapper>
        <styles.LoadingContainer data-testid={DOWNLOAD_PAGE_LOADING_VIEW_TEST_ID}>
          <Loader text="Preparing file to download..." />
        </styles.LoadingContainer>
      </AuthWrapper>
    )
  }

  return (
    <AuthWrapper>
      <styles.LogoContainer data-testid={DOWNLOAD_PAGE_SUCCESS_VIEW_TEST_ID}>
        <AirlockModuleIcon />
        <styles.LogoOverlay success>
          <icons.CheckInCircleSmall />
        </styles.LogoOverlay>
      </styles.LogoContainer>
      <styles.Title>Download started</styles.Title>
      <styles.Description>If it did not start, please try it again by refresh this page.</styles.Description>
    </AuthWrapper>
  )
}

export default DownloadPage
