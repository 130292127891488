import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #6f777c;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`

export const InnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.background.main};
`

export const StyledContent = styled.pre`
  display: flex;
  height: 100%;
  max-height: 650px;
  overflow-y: auto;
  width: 100%;
`

export const TextFileContent = styled.div`
  width: 100%;
`
