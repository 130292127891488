import { Tooltip, icons } from '@lifebit-ai/lds'
import { SubscriptionStatuses } from 'enums'
import React from 'react'

import { StatusIconWrapper } from './SubscriptionStatusIcon.styles'

interface SubscriptionStatusIconProps {
  status: SubscriptionStatuses
  size?: 'small' | 'medium'
}

const getStatusIcon = (status: SubscriptionStatuses, size = 'small'): React.ReactElement | null => {
  switch (status) {
    case SubscriptionStatuses.Approved:
      return size === 'small' ? (
        <icons.CheckInCircleSmall data-testid="approved-icon" />
      ) : (
        <icons.CheckInCircleMedium data-testid="approved-icon" />
      )
    case SubscriptionStatuses.Pending:
      // TODO (LDS) export the clock medium icon
      return size === 'small' ? (
        <icons.ClockSmall data-testid="pending-icon" />
      ) : (
        <icons.ClockMedium data-testid="pending-icon" />
      )
    case SubscriptionStatuses.Rejected:
      return size === 'small' ? (
        <icons.CloseInCircleSmall data-testid="rejected-icon" />
      ) : (
        <icons.CloseInCircleMedium data-testid="rejected-icon" />
      )
    case SubscriptionStatuses.ReSubmitted:
      return size === 'small' ? (
        <icons.RefreshSmall data-testid="resubmitted-icon" />
      ) : (
        <icons.RefreshMedium data-testid="resubmitted-icon" />
      )
    default:
      return null
  }
}

export const getStatusText = (status: SubscriptionStatuses): string | null => {
  switch (status) {
    case SubscriptionStatuses.Approved:
      return 'Access granted'
    case SubscriptionStatuses.Pending:
      return 'Review required'
    case SubscriptionStatuses.ReSubmitted:
      return 'Review required (re-submitted)'
    case SubscriptionStatuses.Rejected:
      return 'Access rejected'
    default:
      return 'Invalid status'
  }
}

const SubscriptionStatusIcon: React.FC<SubscriptionStatusIconProps> = ({ status, size = 'small' }) => {
  const icon = getStatusIcon(status, size)
  if (!icon) return null

  return (
    <Tooltip title={getStatusText(status)} placement="top">
      <StatusIconWrapper status={status}>{icon}</StatusIconWrapper>
    </Tooltip>
  )
}

export default SubscriptionStatusIcon
