import { TableColumn, TableRow } from '@lifebit-ai/lds'
import React from 'react'

import PopoverForOverflownText from 'modules/general/popovers/PopoverForOverflownText'
import { StyledHeaderName } from 'modules/subscriptions/analysis-results/AnalysisResults.styles'
import ResultState from 'modules/subscriptions/analysis-results/ResultState'
import { formatDate } from 'utils/date'

import { getExportRequestName } from './utils'

export const columnsForFilesAndFolders: TableColumn<Result>[] = [
  {
    title: 'Status',
    render: (row: TableRow<Result>) => {
      const result = row.original
      return <ResultState state={result.status} />
    },
    id: 'status',
    width: 120
  },
  {
    title: 'Name',
    id: 'name',
    render: (row: TableRow<Result>) => {
      const name = getExportRequestName(row.original)
      return <PopoverForOverflownText title={name}>{name}</PopoverForOverflownText>
    },
    width: 220
  },
  {
    title: 'Workspace name',
    id: 'workspaceName',
    render: (row: TableRow<Result>) => {
      const { workspaceName } = row.original
      return <PopoverForOverflownText title={workspaceName}>{workspaceName}</PopoverForOverflownText>
    },
    width: 170
  },
  {
    title: 'Workspace ID',
    render: (row: TableRow<Result>) => {
      const { workspaceId } = row.original
      return <PopoverForOverflownText title={workspaceId}>{workspaceId}</PopoverForOverflownText>
    },
    id: 'workspaceId',
    width: 220
  },
  {
    title: 'Requested by',
    render: (row: TableRow<Result>) => {
      const { requestingUser } = row.original
      return (
        <PopoverForOverflownText title={requestingUser.email.toLowerCase()}>
          {requestingUser.email.toLowerCase()}
        </PopoverForOverflownText>
      )
    },
    id: 'email',
    width: 200
  },
  {
    title: 'Requested on',
    render: (row: TableRow<Result>) => <StyledHeaderName>{formatDate(row.original.createdAt)}</StyledHeaderName>,
    id: 'createdAt',
    width: 200
  }
]

export const columnsForAnalysis: TableColumn<Result>[] = [
  {
    title: 'Status',
    render: (row: TableRow<Result>) => <ResultState state={row.original.status} />,
    id: 'status',
    width: 120
  },
  {
    title: 'Workspace name',
    render: (row: TableRow<Result>) => {
      const { workspaceName } = row.original
      return <PopoverForOverflownText title={workspaceName}>{workspaceName}</PopoverForOverflownText>
    },
    id: 'workspaceName',
    width: 170
  },
  {
    title: 'Workspace ID',
    render: (row: TableRow<Result>) => {
      const { workspaceId } = row.original
      return <PopoverForOverflownText title={workspaceId}>{workspaceId}</PopoverForOverflownText>
    },
    id: 'resourceTypeName',
    width: 220
  },
  {
    title: 'Analysis name',
    render: (row: TableRow<Result>) => {
      const { description } = row.original
      return <PopoverForOverflownText title={description}>{description}</PopoverForOverflownText>
    },
    id: 'description'
  },
  {
    title: 'Pipeline',
    render: (row: TableRow<Result>) => {
      const { resourceTypeName } = row.original
      return <PopoverForOverflownText title={resourceTypeName}>{resourceTypeName ?? '-'}</PopoverForOverflownText>
    },
    id: 'pipeline'
  },
  {
    title: 'Requested by',
    render: (row: TableRow<Result>) => {
      const { requestingUser } = row.original
      return (
        <PopoverForOverflownText title={requestingUser.email.toLowerCase()}>
          {requestingUser.email.toLowerCase()}
        </PopoverForOverflownText>
      )
    },
    id: 'requestedBy'
  }
]
