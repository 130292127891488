import styled, { css } from 'styled-components'

export const iconSize = css`
  height: 36px;
  width: 36px;
`

export const NavbarWrapper = styled.div`
  align-items: center;
  background: linear-gradient(180deg, #162f56 0%, #08172f 100%);
  backdrop-filter: blur(24px);
  bottom: 0;
  box-shadow: ${({ theme }) => theme.shadow['02']};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  max-width: 92px;
  padding: ${({ theme }) => theme.size.space['06']} 0;
  width: 64px;
`

export const NavHeader = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.space['03']};
  transition: all 300ms ease-in-out;
  transform: scale(1);

  span {
    color: ${({ theme }) => theme.color.base.neutral.white};
    font-size: 10px;
    font-weight: ${({ theme }) => theme.font.weight.medium};
  }

  &:hover {
    transform: scale(1.03);
  }
`

export const FooterNavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.36);
  }
`

export const LogoContainer = styled.div`
  ${iconSize};
  align-items: center;
  background: ${({ theme }) => theme.color.base.neutral.black};
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: ${({ theme }) => theme.size.border.radius['03']};
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.size.space['02']};

  svg {
    path {
      fill: ${({ theme }) => theme.color.base.neutral.white};
    }
  }
`

export const ActionBtns = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.size.space['05']};
`

export const HelpButton = styled.div`
  ${iconSize};
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;

  svg path {
    path {
      fill: ${({ theme }) => theme.color.content.secondary};
    }
  }
`
