import { Button, Tooltip, bodySmallStyles, icons, labelLargeMediumStyles } from '@lifebit-ai/lds'
import styled from 'styled-components'

// Icons
export const ChevronRightMediumIcon = styled(icons.ChevronRightMedium)``

// Tooltip
export const NewTooltip = styled(Tooltip)``

// Buttons
export const NewButton = styled(Button)``

// Hyperlink
export const Hyperlink = styled.span`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.feedback.action.default};
  cursor: pointer;
  text-decoration: underline;
`

// Accordion
export const AccordionContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.surface};
  border-radius: ${({ theme }) => theme.size.border.radius['03']};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.space['03']};
  padding: ${({ theme }) => theme.size.space['05']};
`

export const AccordionHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
`

export const AccordionHeaderContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: ${({ theme }) => theme.size.space['03']};
`

export const AccordionHeaderTitle = styled.span`
  ${labelLargeMediumStyles}
  color: ${({ theme }) => theme.color.content.main};
  font-size: ${({ theme }) => theme.size.font.xsmall};
  flex: 1;
`

interface AccordionHeaderIconContainerProps {
  expanded?: boolean
}
export const AccordionHeaderIconContainer = styled.div<AccordionHeaderIconContainerProps>`
  display: flex;
  align-items: center;
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform 300ms ease;

  ${({ expanded }) =>
    expanded &&
    `
    transform: rotate(90deg);
  `}

  svg {
    path {
      fill: ${({ theme }) => theme.color.content.secondary as string};
    }
  }
`

export const AccordionHeaderActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.size.space['04']};
`

interface AccordionBodyProps {
  expanded?: boolean
}
export const AccordionBody = styled.div<AccordionBodyProps>`
  ${bodySmallStyles};
  height: 0px;
  display: none;
  max-width: 640px;
  opacity: 0;
  padding-bottom: 0;
  padding-left: ${({ theme }) => theme.size.space['07']};
  padding-top: 0;
  transition: all 300ms ease;
  width: 100%;

  ${({ expanded }) =>
    expanded &&
    `
    display: flex;
    height: 100%;
    opacity: 1;
  `}
`
