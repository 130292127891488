import api from 'api'
import axios from 'axios'
import { getApiServerBeUrl } from 'env'

import type { LoginWithMagicParams, ValidateMagicLinkParams } from './types'

export const loginWithMagic = async (params: LoginWithMagicParams): Promise<void> => {
  await axios({
    method: 'POST',
    url: `${getApiServerBeUrl()}/auth/magic-login`,
    data: params
  })
}

export const validateMagicLink = async (params: ValidateMagicLinkParams): Promise<UserAuth> => {
  const { data } = await api.get<UserAuth>('/auth/magic-login/callback', { params })
  return data
}
