import { Alert, Button, Input, Label, Modal } from '@lifebit-ai/lds'
import { Formik } from 'formik'
import { Form } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { Body, Description, ErrorMsg, Footer, Wrapper } from './RejectionModal.styles'

interface RejectionModalProps {
  action(reason: string): void
  onClose(): void
  isLoading: boolean
  isError: boolean
  isOpen?: boolean
  title?: string
  description?: string
  cancelTitle?: string
  okTitle?: string
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().required('Reason is required')
})

const RejectionModal: React.FC<RejectionModalProps> = ({
  onClose,
  isOpen,
  isLoading,
  isError,
  action,
  title = 'Reject request',
  description = 'Request will be rejected.',
  cancelTitle = 'Cancel',
  okTitle = 'Reject'
}) => {
  const onSubmit = (values: { reason: string }) => {
    action(values.reason)
  }

  return (
    <Modal
      data-testid="rejection-modal"
      open={isOpen}
      onCancel={onClose}
      size="small"
      title={title}
      footer={null}
      destroyOnClose
      closable
    >
      <Wrapper>
        <Description>{description}</Description>

        <Formik<{ reason: string }>
          validateOnMount
          initialValues={{ reason: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleChange, isValid }) => (
            <Form>
              <Body>
                {isError && (
                  <ErrorMsg>
                    <Alert showIcon type={'danger'}>
                      There was an error while rejecting. Please try again or contact the administrator.
                    </Alert>
                  </ErrorMsg>
                )}

                <Input
                  textArea
                  id="reason"
                  data-testid={'reject-reason'}
                  label={<Label>Please provide the reason for the rejection</Label>}
                  placeholder="Reason"
                  onChange={handleChange}
                />
              </Body>

              <Footer>
                <Button
                  size="large"
                  loading={isLoading}
                  danger
                  htmlType="submit"
                  disabled={!isValid}
                  data-testid="submit-reject-button"
                >
                  {okTitle}
                </Button>
              </Footer>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  )
}

export default RejectionModal
