import { Alert, Button, Modal } from '@lifebit-ai/lds'
import React from 'react'

import { Buttons, Content, Description, ErrorMsg, Wrapper } from './ApprovalModal.styles'

interface ApprovalModalProps {
  title: string
  description?: string
  content?: React.ReactElement
  errorMessage?: string
  action(): void
  onClose(): void
  isLoading: boolean
  isError: boolean
  isOpen?: boolean
  okTitle?: string
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({
  onClose,
  title = 'Approve request?',
  description = 'Do you want to approve request?',
  content,
  errorMessage = 'There was an error while approving request. Please try again or contact the administrator.',
  isOpen,
  isLoading,
  isError,
  action,
  okTitle = 'Approve'
}) => {
  return (
    <Modal
      data-testid="approval-modal"
      open={isOpen}
      onCancel={onClose}
      size="small"
      footer={null}
      title={title}
      destroyOnClose
      closable
    >
      <Wrapper>
        {isError && (
          <ErrorMsg>
            <Alert showIcon type="danger">
              {errorMessage}
            </Alert>
          </ErrorMsg>
        )}

        <Description>{description}</Description>
        <Content>{content}</Content>

        <Buttons>
          <Button size="large" type="primary" data-testid="submit-approve-button" onClick={action} loading={isLoading}>
            {okTitle}
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  )
}

export default ApprovalModal
