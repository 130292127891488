import { useState } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from 'services/queryKeys'
import { getFileContentBySignedUrl } from 'services/results'

export const useFileContentsQuery = (url: string) => {
  // Local state to track progress (0–100)
  const [progress, setProgress] = useState(0)

  const onDownloadProgress = (event: ProgressEvent) => {
    if (event.total) {
      // Calculate the percentage completed with one decimal point
      const percentCompleted = Number(((event.loaded * 100) / event.total).toFixed(1))
      setProgress(percentCompleted)
    }
  }

  const query = useQuery({
    queryKey: QueryKeys.GetFileContentsFromSignedUrl(url),
    queryFn: ({ signal }) => getFileContentBySignedUrl(url, onDownloadProgress, signal),
    enabled: !!url,
    retry: false
  })

  return { progress, ...query }
}

export default useFileContentsQuery
