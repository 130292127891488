import React, { useState } from 'react'

import * as styles from './Accordion.styles'

export const ACCORDION_HEADER_TEST_ID = 'accordion-header-test-id'

interface AccordionProps {
  title: React.ReactNode | string
  children: React.ReactNode
  actionTitle?: string
  onAction?: () => void
  totalItems?: number
  viewAllAction?: () => void
  actionDisabledTitle?: string
  link?: string
  expanded?: boolean
}
const Accordion: React.FC<AccordionProps> = ({
  title,
  totalItems,
  actionTitle,
  viewAllAction,
  onAction,
  actionDisabledTitle,
  children,
  expanded = false
}) => {
  const [accordionIsExpanded, setAccordionIsExpanded] = useState(expanded)

  const renderActionButton = () => {
    if (!actionTitle || !onAction) return null

    if (actionDisabledTitle) {
      return (
        <styles.NewTooltip title={actionDisabledTitle}>
          <styles.NewButton type="primary" disabled>
            {actionTitle}
          </styles.NewButton>
        </styles.NewTooltip>
      )
    }

    return (
      <styles.NewButton type="primary" onClick={onAction}>
        {actionTitle}
      </styles.NewButton>
    )
  }

  return (
    <styles.AccordionContainer>
      <styles.AccordionHeader
        data-testid={ACCORDION_HEADER_TEST_ID}
        onClick={() => setAccordionIsExpanded(!accordionIsExpanded)}
      >
        <styles.AccordionHeaderContainer>
          <styles.AccordionHeaderIconContainer expanded={accordionIsExpanded}>
            <styles.ChevronRightMediumIcon />
          </styles.AccordionHeaderIconContainer>
          <styles.AccordionHeaderTitle>{title}</styles.AccordionHeaderTitle>
        </styles.AccordionHeaderContainer>

        <styles.AccordionHeaderActionsContainer>
          {viewAllAction && <styles.Hyperlink onClick={viewAllAction}>View all</styles.Hyperlink>}
          {renderActionButton()}
        </styles.AccordionHeaderActionsContainer>
      </styles.AccordionHeader>
      <styles.AccordionBody expanded={accordionIsExpanded}>{accordionIsExpanded && children}</styles.AccordionBody>
    </styles.AccordionContainer>
  )
}

export default Accordion
