import { QueryObserverResult, UseQueryOptions, useQuery } from 'react-query'

import { QueryKeys } from 'services/queryKeys'
import { getFileContent } from 'services/results'

export const useFileContent = (
  resultId: string,
  bucket: string,
  fileKey: string,
  fetchSignedUrlOnly: boolean = false,
  isDisabled: boolean = false,
  options?: UseQueryOptions<GetFileContentResponse | undefined, { fetchSignedUrlOnly: boolean }>
): QueryObserverResult<GetFileContentResponse | undefined, { fetchSignedUrlOnly: boolean }> => {
  return useQuery<GetFileContentResponse | undefined, { fetchSignedUrlOnly: boolean }>({
    queryKey: QueryKeys.GetFileContent(resultId, bucket, fileKey, fetchSignedUrlOnly),
    queryFn: () => getFileContent(resultId, bucket, fileKey, fetchSignedUrlOnly),
    enabled: !isDisabled,
    ...options
  })
}
