import { Alert, Loader } from '@lifebit-ai/lds'
import React, { CSSProperties, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window'

import { useFileContentsQuery } from 'hooks/useFileContentsQuery'

import * as styles from './FileView.styles'

interface FileViewProps {
  url: string
}

const MAX_NUMBER_OF_LINES_ALLOWED = 1100000

export const FileView: React.FC<FileViewProps> = ({ url }) => {
  const { data, isLoading, progress, isError } = useFileContentsQuery(url)
  const [areLinesHidden, setAreLinesHidden] = useState(false)

  const arrayOfLines = useMemo(() => {
    if (!data) return undefined

    const dataAsArr = data.split('\n')

    setAreLinesHidden(dataAsArr.length > MAX_NUMBER_OF_LINES_ALLOWED)

    return dataAsArr.filter((i, index) => index < MAX_NUMBER_OF_LINES_ALLOWED).map(i => i.trim())
  }, [data])

  if (isError) {
    return (
      <styles.EmptyWrapper>
        <Alert type="danger">Error loading file contents.</Alert>
      </styles.EmptyWrapper>
    )
  }

  if (isLoading || !arrayOfLines) {
    return (
      <styles.EmptyWrapper>
        <Loader text={`Loading file contents${progress ? ` (${progress}%)...` : ''}`} />
      </styles.EmptyWrapper>
    )
  }

  if (!arrayOfLines.length) {
    return <styles.EmptyWrapper>No contents</styles.EmptyWrapper>
  }

  const Row = ({ index, style }: { index: number; style?: CSSProperties }) => (
    <div style={style}>
      <styles.Number>{index + 1}</styles.Number>
      {arrayOfLines[index]}
    </div>
  )

  return (
    <styles.Container>
      {areLinesHidden ? (
        <Alert type="warning">Due to the size of this file you're only previewing a part of it.</Alert>
      ) : null}
      <styles.Wrapper>
        <FixedSizeList height={600} itemCount={arrayOfLines.length} width="100%" itemSize={12}>
          {Row}
        </FixedSizeList>
      </styles.Wrapper>
    </styles.Container>
  )
}

export default FileView
